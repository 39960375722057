<template>
  <v-container
    class="profile-update-container scroll-container"
    v-if="userData"
  >
    <h4
      class="text-uppercase font-weight-semibold"
      v-html="$t('profile.update.title')"
    />

    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row no-gutters>
        <v-col cols="12" md="6" v-if="user.fidelityCard">
          <v-text-field
            class="mt-2"
            v-model="user.fidelityCard"
            :label="`${$t('profile.cartaFedeltà')}`"
            outlined
            rounded
            dense
            disabled
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" md="6">
          <v-select
            class="mt-2"
            v-model="storeToSelect"
            :items="storeList"
            item-text="address.city"
            item-value="warehouseId"
            :label="`${$t('profile.selectedStore')}`"
            outlined
            rounded
            dense
            :rules="[requiredRules()]"
          ></v-select>
        </v-col> -->

        <v-col cols="12" md="6">
          <v-text-field
            :label="`${$t('register.card.step-0.placeholder.name')}`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.nome"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            :label="`${$t('register.card.step-0.placeholder.surname')}`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.cognome"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            class="mt-2"
            v-model="userData.email"
            :label="`${$t('profile.email.email')}`"
            :rules="emailRules"
            required
            outlined
            rounded
            dense
          />
        </v-col>
        <!-- <v-col cols="12" md="6">
          <v-dialog
            v-model="birthDateCal"
            transition="scale-transition"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                :value="birthDate"
                append-icon="$calendar"
                @click:append="birthDateCal = true"
                :label="`${$t('profile.birthDate')}`"
                readonly
                outlined
                rounded
                dense
                v-bind="attrs"
                v-on="on"
                :rules="[requiredRules()]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataNascita"
              :max="maxBirthDate"
              :show-current="pickerDate"
              locale="it-IT"
              no-title
              color="primary"
              format="YYYY-MM-DD"
              @change="birthDateCal = false"
            ></v-date-picker>
          </v-dialog>
        </v-col> -->
        <!-- <v-col cols="12" md="6">
          <v-select
            class="mt-2"
            v-model="userData.sesso"
            :items="genders"
            item-text="label"
            item-value="code"
            :label="`${$t('profile.gender')}`"
            outlined
            rounded
            dense
            :rules="[requiredRules()]"
          ></v-select>
        </v-col> -->
        <v-col cols="12" md="6">
          <v-text-field
            :label="`${$t('profile.address.address')}`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.indirizzo"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :label="`${$t('profile.address.addressNumber')}`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.numeroCivico"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            ref="cap"
            :rules="capRules"
            @input="getCities"
            :label="`${$t('profile.address.postalcode')}`"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.cap"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-if="hasData"
            :label="`${$t('profile.address.city')}`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.localita"
            :loading="loadingCities"
            disabled
          />
          <v-select
            v-else
            ref="selectedCity"
            class="mt-2"
            v-model="selectedCity"
            :items="cityList"
            item-text="city"
            return-object
            :label="`${$t('profile.address.city')} *`"
            outlined
            rounded
            dense
            :disabled="!showCityInput"
            :rules="[requiredRules()]"
            @change="citySelected"
            :no-data-text="$t('profile.address.noCityFoundForCap')"
            :loading="loadingCities"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            ref="province"
            :label="`${$t('profile.address.province')}`"
            :rules="[requiredRules()]"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.provincia"
            disabled
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :label="`${$t('profile.contacts.mobilePhone')}`"
            :rules="phoneRules"
            class="mt-2"
            required
            outlined
            rounded
            dense
            type="text"
            v-model="userData.cellulare"
          />
        </v-col>
      </v-row>
    </v-form>
    <ResponseMessage class="mt-3" :response="response" />

    <!-- UPDATE PERSONAL DATA -->
    <v-col class="pa-0 ml-auto" cols="12" md="5">
      <v-btn
        color="primary"
        large
        depressed
        rounded
        block
        class="text-body-0"
        v-html="$t('profile.updatePersonalData')"
        @click="validate"
      />
    </v-col>

    <!-- DELETE ACCOUNT -->
    <div class="anonymize-block mt-10 px-0">
      <ResponseMessage class="mt-3" :response="responseAnonymazeAccount" />
      <div class="d-flex justify-center mt-3 grey lighten-1 mx-n2 mb-n2 px-2">
        <v-btn
          color="primary"
          depressed
          text
          rounded
          block
          class="my-3 default--text text-decoration-underline"
          @click="confirmAnonymizeAccount()"
          :loading="loadingAnonymizeAccount"
          >{{ $t("profile.anonymizeAccountBtn") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<style lang="scss">
.profile-update-container {
  h2 {
    font-size: 30px;
  }
  a {
    color: var(--v-default-base);
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      font-size: 16px !important;
    }
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px;
  }
  .v-select .v-select__selection--comma {
    color: inherit !important;
    font-weight: normal !important;
  }
  .v-input .v-progress-linear {
    left: 15px;
    width: calc(100% - 30px);
  }
}
</style>
<script>
import {
  requiredValue,
  isMobilePhone,
  isEmail
} from "~/validator/validationRules";
import { isCheckboxTrueBool, isCAP } from "@/customValidationRules";
import ResponseMessage from "@/components/ResponseMessage.vue";
import categoryMixins from "~/mixins/category";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters, mapState } from "vuex";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
import AddressService from "~/service/addressService";
import CustomService from "@/service/customService";

export default {
  name: "ProfileUpdate",
  components: {
    ResponseMessage
  },
  mixins: [categoryMixins],
  directives: { mask },
  data() {
    return {
      requiredRules: requiredValue,
      emailRules: [requiredValue(), isEmail()],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.userData.email || "L'e-mail non coincidono"
      ],
      passwordRules: [requiredValue("Digitare la password")],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.userData.password || "Le password non coincidono"
      ],
      phoneRules: [requiredValue(), isMobilePhone()],
      checkboxRules: [
        isCheckboxTrueBool(
          "È obbligatorio accettare questo consenso per procedere"
        )
      ],
      capRules: [requiredValue(), isCAP()],
      pickerDate: this.$dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD"),
      userData: {},
      dataNascita: null,
      isValid: false,
      emailConfirm: null,
      birthDateCal: false,
      //birthDate: null,
      showPassword: false,
      genders: [
        { code: "M", label: "Maschile" },
        { code: "F", label: "Femminile" }
      ],
      response: {},
      loadingAnonymizeAccount: false,
      responseAnonymazeAccount: {},
      storeToSelect: null,
      storeList: [],
      cityList: [],
      selectedCity: {},
      showCityInput: false,
      loadingCities: false,
      hasData: true
    };
  },
  computed: {
    maxBirthDate() {
      return this.$dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
    },
    birthDate: {
      get: function() {
        if (this.userData.dataNascita) {
          return this.$dayjs(this.dataNascita).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
      set: function(value) {
        this.dataNascita = this.$dayjs(value, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
      }
    },
    ...mapGetters({
      user: "cart/getUser"
    }),
    ...mapState({
      selectedStoreState: ({ custom }) => custom.selectedStore
    }),
    selectedStore() {
      if (this.user && this.user.userId) {
        return this.selectedStoreState[this.user.userId];
      } else {
        return this.selectedStoreState["noAccount"];
      }
    }
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      loadCart: "cart/loadCart",
      selectStore: "custom/selectStore"
    }),
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async getUserData() {
      const result = await AbbondanzaRegistrationService.getUserDetail();
      if (result) {
        this.userData = result;
        this.dataNascita = this.$dayjs(
          this.userData.dataNascita,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        const pdvAsInt = parseInt(this.userData.pdv);
        if (!isNaN(pdvAsInt)) {
          this.storeToSelect = pdvAsInt;
        } else {
          this.storeToSelect = null;
        }
      }
    },
    async getStoreList() {
      let data = await AddressService.findWarehouse();
      this.storeList = data.warehouses;
    },
    async validate() {
      let personalData = this.$refs.form;
      if (personalData.validate()) {
        this.updateProfile();
      }
    },
    back() {
      this.$router.back();
    },
    async updateProfile() {
      this.userData.dataNascita = this.$dayjs(
        this.dataNascita,
        "YYYY-MM-DD"
      ).format("MM/DD/YYYY");
      //this.userData.pdv = this.storeToSelect.toString();
      if (this.userData.pdv) {
        delete this.userData.pdv;
      }
      if (this.userData.nrTessera) {
        delete this.userData.nrTessera;
      }
      let res = await AbbondanzaRegistrationService.updateUserData(
        this.userData
      );
      if (res) {
        console.log(res)
        this.response = res.response;
        this.getUserData();
        this.loadCart();
      }
    },
    async confirmAnonymizeAccount() {
      let title = this.$t("profile.anonymizeProfileDialog.title") + " ";
      let res = await this.$dialog.confirm({
        text: `<h2 class='primary--text text-center'>${title}</h2><p class='text-center font-weight-semibold pre-wrap'>${this.$t(
          "profile.anonymizeProfileDialog.text"
        )}</p>`,
        class: "confirm-coupon-activation",
        actions: {
          false: {
            color: "var(--v-primary-base)",
            rounded: true,
            text: this.$t("common.no")
          },
          true: {
            rounded: true,
            text: this.$t("common.yes")
          }
        }
      });
      if (res) {
        this.anonymizeAccount();
      }
    },
    async anonymizeAccount() {
      this.loadingAnonymizeAccount = true;
      let result = await AbbondanzaRegistrationService.anonymizeAccount();
      this.loadingAnonymizeAccount = false;
      this.responseAnonymazeAccount = result.response;
      await this.doLogout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    },
    async getCities() {
      if (this.$refs["cap"].validate()) {
        this.loadingCities = true;
        let data = await CustomService.searchCitiesFromCap(this.userData.cap);
        this.loadingCities = false;
        this.cityList = data.data?.values;
        this.showCityInput = true;
        this.$nextTick(() => {
          const inputElement = this.$refs.selectedCity.$refs.input;
          inputElement.focus();
          this.$refs.selectedCity.activateMenu();
        });
      } else {
        this.cityList = [];
        this.userData.localita = null;
        this.userData.provincia = null;
        this.$refs["selectedCity"]?.reset();
        this.$refs["province"].reset();
        this.showCityInput = false;
        this.hasData = false;
      }
    },
    citySelected(data) {
      this.userData.localita = data.city;
      this.userData.provincia = data.province;
    }
  },
  async mounted() {
    await this.getUserData();
    await this.getStoreList();
  }
};
</script>
