var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userData
    ? _c(
        "v-container",
        { staticClass: "profile-update-container scroll-container" },
        [
          _c("h4", {
            staticClass: "text-uppercase font-weight-semibold",
            domProps: { innerHTML: _vm._s(_vm.$t("profile.update.title")) }
          }),
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              },
              model: {
                value: _vm.isValid,
                callback: function($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _vm.user.fidelityCard
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t("profile.cartaFedeltà")}`,
                              outlined: "",
                              rounded: "",
                              dense: "",
                              disabled: ""
                            },
                            model: {
                              value: _vm.user.fidelityCard,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "fidelityCard", $$v)
                              },
                              expression: "user.fidelityCard"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t(
                            "register.card.step-0.placeholder.name"
                          )}`,
                          rules: [_vm.requiredRules()],
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          type: "text"
                        },
                        model: {
                          value: _vm.userData.nome,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "nome", $$v)
                          },
                          expression: "userData.nome"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t(
                            "register.card.step-0.placeholder.surname"
                          )}`,
                          rules: [_vm.requiredRules()],
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          type: "text"
                        },
                        model: {
                          value: _vm.userData.cognome,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "cognome", $$v)
                          },
                          expression: "userData.cognome"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t("profile.email.email")}`,
                          rules: _vm.emailRules,
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.userData.email,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "email", $$v)
                          },
                          expression: "userData.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t("profile.address.address")}`,
                          rules: [_vm.requiredRules()],
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          type: "text"
                        },
                        model: {
                          value: _vm.userData.indirizzo,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "indirizzo", $$v)
                          },
                          expression: "userData.indirizzo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t("profile.address.addressNumber")}`,
                          rules: [_vm.requiredRules()],
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          type: "text"
                        },
                        model: {
                          value: _vm.userData.numeroCivico,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "numeroCivico", $$v)
                          },
                          expression: "userData.numeroCivico"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        ref: "cap",
                        staticClass: "mt-2",
                        attrs: {
                          rules: _vm.capRules,
                          label: `${_vm.$t("profile.address.postalcode")}`,
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          type: "text"
                        },
                        on: { input: _vm.getCities },
                        model: {
                          value: _vm.userData.cap,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "cap", $$v)
                          },
                          expression: "userData.cap"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _vm.hasData
                        ? _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              label: `${_vm.$t("profile.address.city")}`,
                              rules: [_vm.requiredRules()],
                              required: "",
                              outlined: "",
                              rounded: "",
                              dense: "",
                              type: "text",
                              loading: _vm.loadingCities,
                              disabled: ""
                            },
                            model: {
                              value: _vm.userData.localita,
                              callback: function($$v) {
                                _vm.$set(_vm.userData, "localita", $$v)
                              },
                              expression: "userData.localita"
                            }
                          })
                        : _c("v-select", {
                            ref: "selectedCity",
                            staticClass: "mt-2",
                            attrs: {
                              items: _vm.cityList,
                              "item-text": "city",
                              "return-object": "",
                              label: `${_vm.$t("profile.address.city")} *`,
                              outlined: "",
                              rounded: "",
                              dense: "",
                              disabled: !_vm.showCityInput,
                              rules: [_vm.requiredRules()],
                              "no-data-text": _vm.$t(
                                "profile.address.noCityFoundForCap"
                              ),
                              loading: _vm.loadingCities
                            },
                            on: { change: _vm.citySelected },
                            model: {
                              value: _vm.selectedCity,
                              callback: function($$v) {
                                _vm.selectedCity = $$v
                              },
                              expression: "selectedCity"
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        ref: "province",
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t("profile.address.province")}`,
                          rules: [_vm.requiredRules()],
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          type: "text",
                          disabled: ""
                        },
                        model: {
                          value: _vm.userData.provincia,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "provincia", $$v)
                          },
                          expression: "userData.provincia"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          label: `${_vm.$t("profile.contacts.mobilePhone")}`,
                          rules: _vm.phoneRules,
                          required: "",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          type: "text"
                        },
                        model: {
                          value: _vm.userData.cellulare,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "cellulare", $$v)
                          },
                          expression: "userData.cellulare"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ResponseMessage", {
            staticClass: "mt-3",
            attrs: { response: _vm.response }
          }),
          _c(
            "v-col",
            { staticClass: "pa-0 ml-auto", attrs: { cols: "12", md: "5" } },
            [
              _c("v-btn", {
                staticClass: "text-body-0",
                attrs: {
                  color: "primary",
                  large: "",
                  depressed: "",
                  rounded: "",
                  block: ""
                },
                domProps: {
                  innerHTML: _vm._s(_vm.$t("profile.updatePersonalData"))
                },
                on: { click: _vm.validate }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "anonymize-block mt-10 px-0" },
            [
              _c("ResponseMessage", {
                staticClass: "mt-3",
                attrs: { response: _vm.responseAnonymazeAccount }
              }),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-center mt-3 grey lighten-1 mx-n2 mb-n2 px-2"
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "my-3 default--text text-decoration-underline",
                      attrs: {
                        color: "primary",
                        depressed: "",
                        text: "",
                        rounded: "",
                        block: "",
                        loading: _vm.loadingAnonymizeAccount
                      },
                      on: {
                        click: function($event) {
                          return _vm.confirmAnonymizeAccount()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("profile.anonymizeAccountBtn")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }